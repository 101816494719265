import React, { useState, useEffect } from 'react'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import "./video-highlight.scss"
import BackgroundImage from 'gatsby-background-image'

import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css';
import ReactPlayer from "react-player";
import AOS from 'aos'
import "aos/dist/aos.css"

const FacebookPlayer = require('react-facebook-player');

export default function VideoHighlight({matchData, latestVidData}) {

  //Image Data
  const imageData = latestVidData?.backgroundImage?.localFile?.childImageSharp?.fluid

  //Open/Close Modal Video
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const videoId = "791321682319980";
  const appId = "2F466741555253337";

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <BackgroundImage 
          Tag="div"
          fluid={imageData}
          className="latest-video-section sec-spacer"
      >
        <div className="overly-bg"></div>
        <div className="container">
          <h3 className="title-bg">Latest video</h3>
          <div className="row">
            <div className="col-lg-8">
              <div className="video-area mmb-40"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="5">
                <GatsbyImage
                image={getImage(matchData[0]?.customFields?.thumbnail?.localFile)}
                alt={"Match Image"}
                className=""
                />
                {/* <FacebookPlayer
                  videoId={videoId}
                  appId={appId}
                /> */}
                <div className="videos-icon popup-youtube">
                  <div className="popup-youtube">
                  <FontAwesomeIcon className="" icon={faPlay} onClick={onOpenModal}/>
                    <Modal 
                      open={open}
                      onClose={onCloseModal}
                      center
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'customModal',
                      }}
                    >
                    <ReactPlayer
                      url={matchData[0]?.customFields?.matchLink?.url}
                      className="react-player"
                    />
                    </Modal>
                  </div>
                    
                </div>
              </div>
            </div>
            <div className="col-lg-4 latest-news"
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="5">
              {matchData?.slice(0,3)?.map((report, index) => {
                return (
                  <div className="inner-news small-news" key={index}>
                  <div className="news-img-container">
                    <Link className="" to={`/blog/${report?.slug}`} aria-label="image">
                      <GatsbyImage
                        image={getImage(report?.customFields?.thumbnail?.localFile)}
                        alt={"Match Image"}
                        className="news-img"
                      />
                    </Link>
                  </div>
                  <div className="news-text">
                    <div className="title">
                      <div className="link-latest-news">
                        {report?.customFields?.title}
                      </div>
                    </div>
                  <div className="score">{report?.customFields?.score ? `Highlights ${report?.customFields?.score}` : ""}</div>
                    <span className="date">{report?.customFields?.date}</span>
                    <p className="venue">{report?.customFields?.venue}</p>
                  </div>
                </div>
                )
              })}
            </div>
          </div>
        </div>
        </BackgroundImage>
    </>
  )
}
