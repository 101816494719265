import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation, FreeMode, Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import BackgroundImage from "gatsby-background-image"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/thumbs"

import "./latest-new-result.scss"

//AOS Import
import AOS from "aos"
import "aos/dist/aos.css"

export default function LatestNewResult({ postData, fixtures, resultImage }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const newsData = postData

  //Get Future Match Fixtures data
  const currentDate = new Date()

  //Filter out the Future Matches data for Match Table. Convert fixtureDate format to JS Date format
  const futureData = fixtures?.filter(
    match =>
      new Date(
        (match?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1")
      ) < currentDate
  )

  //Sort the fixtureDate to display on the Match Table from the closest future date to furthest date
  const futureMatches = futureData.sort(
    (a, b) =>
      new Date((b.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1")) -
      new Date((a?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1"))
  )

  //Image Data
  const imageData =
    resultImage?.backgroundImage?.localFile?.childImageSharp?.fluid

  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
    AOS.refresh()
  }, [])

  return (
    <>
      {/*  <!-- All news area Start Here--> */}
      <div className="all-news-area sec-spacer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="title-bg">Raiders News</h3>
              <div className="row">
                <div className="col-lg-9 col-md-12 col-sm-12">
                  <div className="latest-news-slider">
                    <Swiper
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="big-latest-swiper"
                    >
                      {newsData?.slice(0, 3).map((report, index) => {
                        
                        
                        return (
                          <SwiperSlide key={report?.slug + index}>
                            <div
                              className="news-normal-block text-left"
                              data-aos="fade-up"
                              data-aos-offset="100"
                              data-aos-delay="5"
                            >
                              <div className="news-img">
                                <GatsbyImage
                                  image={getImage(
                                    report?.featuredImage?.node?.localFile
                                  )}
                                  alt={report?.title}
                                />
                              </div>
                              <h4 className="news-title">
                                {report?.title}
                              </h4>
                              <div
                                className="news-desc"
                                dangerouslySetInnerHTML={{
                                  __html: report?.excerpt,
                                }}
                              ></div>
                              <div className="news-btn">
                                <Link
                                  className="primary-btn"
                                  to={`/blog/${report?.slug}`}
                                >
                                  Read more
                                </Link>
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
                {/*  <!-- 3 Small Swipers --> */}
                <div className="col-lg-3 col-md-12 col-sm-12 d-none d-lg-block">
                  <Swiper
                    modules={[FreeMode, Navigation, Thumbs]}
                    direction={"vertical"}
                    breakpoints={{
                      320: {
                        slidesPerView: 0,
                      },
                      375: {
                        slidesPerView: 0,
                      },
                      425: {
                        slidesPerView: 0,
                      },
                      768: { slidesPerView: 0 },
                      1024: { slidesPerView: 3 },

                      1440: { slidesPerView: 3 },
                    }}
                    spaceBetween={20}
                    slidesPerView={3}
                    onSwiper={setThumbsSwiper}
                    watchSlidesProgress={true}
                    navigation={false}
                    className="small-latest-swiper"
                  >
                    {newsData?.slice(0, 3).map((report, index) => {
                      
                      return (
                        <SwiperSlide key={report.id}>
                          <GatsbyImage
                            image={getImage(
                              report.featuredImage?.node.localFile
                            )}
                            alt={"Match Image"}
                            className="img-vertical"
                          />
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </div>

            {/*  <!-- Right area here--> */}
            <div className="col-md-4 d-none">
              <div className="sidebar-area right-side-area">
                <h3 className="title-bg">Recent Results</h3>
                <BackgroundImage
                  Tag="div"
                  fluid={imageData}
                  className="today-match-teams text-center"
                >
                  <div className="overly-bg"></div>
                  <div className="title-head">
                    <h4>Last Match Result </h4>
                    <span
                      className="date"
                      data-aos="fade-up"
                      data-aos-offset="100"
                      data-aos-delay="5"
                    >
                      {futureMatches[0]?.fixtures?.fixtureDate}
                    </span>
                  </div>
                  <div
                    className="today-score row"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="5"
                  >
                    <div className="today-match-team col-lg-4 col-md-12 col-sm-12">
                      <GatsbyImage
                        image={getImage(
                          futureMatches[0]?.fixtures?.homeTeamLogo?.localFile
                        )}
                        alt={"Match Image"}
                        className="result-img"
                      />
                      <h4>{futureMatches[0]?.fixtures?.homeTeam}</h4>
                    </div>
                    <div className="today-final-score col-lg-4 col-md-12 col-sm-12">
                      {futureMatches[0]?.fixtures?.score
                        ? futureMatches[0]?.fixtures?.score
                        : "TBA"}
                      <h4>final score</h4>
                    </div>
                    <div className="today-match-team col-lg-4 col-md-12 col-sm-12">
                      <GatsbyImage
                        image={getImage(
                          futureMatches[0]?.fixtures?.awayTeamLogo?.localFile
                        )}
                        alt={"Match Image"}
                        className="result-img"
                      />
                      <h4>{futureMatches[0]?.fixtures?.awayTeam}</h4>
                    </div>
                  </div>
                  {/*  <!-- Previous Results Section goes here --> */}
                  <div className="title-head">
                    <h4>Previous Results</h4>
                  </div>
                  <div className="recent-match-results">
                    {futureMatches?.slice(1, 5).map((fixture, index) => {
                      
                      return (
                        <div className="single-result" key={fixture.slug}>
                          <div className="team-result clearfix">
                            <div
                              className="text-left match-result-list single-part"
                              data-aos="fade-up"
                              data-aos-offset="100"
                              data-aos-delay="5"
                            >
                              <GatsbyImage
                                image={getImage(
                                  fixture?.fixtures?.homeTeamLogo?.localFile
                                )}
                                alt={fixture?.fixtures?.homeTeam}
                                className="result-img"
                              />
                              {fixture?.fixtures?.homeTeam}
                            </div>
                            <div
                              className="text-center match-score single-part"
                              data-aos="fade-up"
                              data-aos-offset="100"
                              data-aos-delay="5"
                            >
                              <span className="score">
                                {fixture?.fixtures?.score
                                  ? fixture?.fixtures?.score
                                  : "TO BE UPDATED"}
                              </span>
                            </div>
                            <div
                              className="text-right match-result-list single-part"
                              data-aos="fade-up"
                              data-aos-offset="100"
                              data-aos-delay="5"
                            >
                              <GatsbyImage
                                image={getImage(
                                  fixture?.fixtures?.awayTeamLogo?.localFile
                                )}
                                alt={fixture?.fixtures?.awayTeam}
                                className="result-img"
                              />
                              {fixture?.fixtures?.awayTeam}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="view-all-result">
                      <Link to="/matches-events">
                        View All{" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </BackgroundImage>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- All news area end Here--> */}
    </>
  )
}
