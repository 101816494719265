import React, {useEffect} from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./sponsor-slider.scss"
//AOS import
import AOS from 'aos'
import "aos/dist/aos.css"

export default function SponsorSlider({ sponsorLogoList, bgImage }) {

    useEffect(() => {
        AOS.init({
          duration: 2000
        });
        AOS.refresh();
      }, []);

    const imageData = bgImage?.backgroundImage?.localFile?.childImageSharp?.fluid
    return (
        <>
        <div className="container pt-5">
            <div className="row justify-center">
                <div className="col-12 text-center">
                <h3 className="title-bg">Club Sponsors</h3>
                </div>
            </div>
        </div>
           
            <BackgroundImage 
                Tag="div"
                fluid={imageData}
                className="clicent-logo-section sec-spacer"
            >
                <div className="overly-bg"></div>
                <div className="container">
                    <div className="">
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay, Mousewheel]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                375: {
                                    slidesPerView: 1,
                                },
                                425: {
                                    slidesPerView: 1,
                                },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 4 },

                                1440: { slidesPerView: 5 },
                            }}
                            slidesPerView={4}
                            loop={true}
                            spaceBetween={30}
                            pagination={false}
                            navigation={false}
                            autoplay={true}
                            keyboard={true}
                            className="sponsor-swiper"
                        >
                            {
                                sponsorLogoList.map((sponsor, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="item"
                                            data-aos="fade-up"
                                            data-aos-offset="100"
                                            data-aos-delay="5">
                                                <div className="single-logo">
                                                    <GatsbyImage image={getImage(sponsor.logo?.localFile.childImageSharp.gatsbyImageData)} alt={"About raiders"} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </div>
            </BackgroundImage>
        </>
    )
}