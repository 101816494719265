import React, { useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import AOS from 'aos'
import "aos/dist/aos.css"

const TextImage = ({ heading, description, image }) => {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);

  return (
    <div className="rs-club" style={{ paddingTop: "100px" }}>
      <div className="container">
        <div className="title-bg">{heading}</div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="rs-club-text">
              <div dangerouslySetInnerHTML={{ __html: description }}
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="5"
              ></div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="rs-club-image"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="5">
              <GatsbyImage image={image} alt={"About raiders"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextImage
