import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./upcoming-match.scss"
//AOS Import
import AOS from "aos"
import "aos/dist/aos.css"
import { Link } from "gatsby"

export default function UpcomingMatch({
  upComingMatchData,
  upComingMatchImage,
}) {

  //Get Future Match Fixtures data
  const currentDate = new Date()

  //Filter out the Future Matches data for Match Table. Convert fixtureDate format to JS Date format
  const futureData = upComingMatchData.filter(
    match =>
      new Date(
        (match?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1")
      ) > currentDate
  )

  //Sort the fixtureDate to display on the Match Table from the closest future date to furthest date
  const futureMatches = futureData.sort(
    (a, b) =>
      new Date((a.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1")) -
      new Date((b?.fixtures?.fixtureDate).replace(/(\d+[/])(\d+[/])/, "$2$1"))
  )

  //Image Data
  const imageData =
    upComingMatchImage?.sectionBgImage?.localFile?.childImageSharp?.fluid

  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
    AOS.refresh()
  }, [])

  return (
    <>
      <div className="container pt-5">
        <div className="row justify-center">
          <div className="col-12 text-center">
            <h3 className="title-bg d-none">Upcoming Fixtures</h3>
          </div>
        </div>
      </div>
      <BackgroundImage style={{height:'400px'}} Tag="div" fluid={imageData} className="upcoming-section">
        <div className="overly-bg"></div>
        <div className="container">
          <h2 className="title-bg">{upComingMatchData?.mainHeading}</h2>
          <Swiper
            modules={[Navigation, Pagination, Autoplay, Mousewheel]}
            slidesPerView={1}
            loop={true}
            spaceBetween={30}
            pagination={false}
            navigation={false}
            autoplay={true}
            mousewheel={false}
            keyboard={true}
            className="upcoming-match-swiper"
          >
            {futureMatches?.map((match, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="row item"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="5"
                  >
                    <div className="col-lg-4 col-md-4 col-sm-12 first">
                      <GatsbyImage
                        image={getImage(
                          match.fixtures.homeTeamLogo?.localFile
                            ?.childImageSharp.gatsbyImageData
                        )}
                        alt={"home team logo"}
                      />
                      <section>{match.fixtures.homeTeam}</section>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <span className="date">{match.fixtures.time}</span>
                      <span className="vs">VS</span>
                      <span>{match.fixtures.stadium}</span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 last">
                      <GatsbyImage
                        image={getImage(
                          match.fixtures.awayTeamLogo?.localFile.childImageSharp
                            .gatsbyImageData
                        )}
                        alt={"away team logo"}
                      />
                      <section>{match.fixtures.awayTeam}</section>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          
        </div>
        <div className="cta-button-overlay">
            {/* <Link className="primary-btn" to="/fixtures">View all fixtures</Link> */}
          </div>
      </BackgroundImage>
    </>
  )
}
