import React, {useEffect} from 'react'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./homepage-hero.scss"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

const HomepageHero = ({ heroData }) => {

    useEffect(() => {
      AOS.init({
        duration: 2000
      });
      AOS.refresh();
    }, []);

  return (
    <>
      <div className="slider-sections-area3">
        <div id="slider-images" className="flexslider">
          <ul className="slides">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              slidesPerView={1}
              loop={true}
              spaceBetween={30}
              pagination={false}
              navigation={false}
              autoplay={true}
              keyboard={true}
              className="homepage-hero-swiper"
            >
              {heroData?.map((heroContent, index) => {
                return (
                  <SwiperSlide key={index}>
                    <li>
                      <GatsbyImage
                        image={getImage(heroContent?.backgroundImage?.localFile)}
                        alt={"Raiders Placeholder"}
                        className="hero_homepage"
                      />

                      <div className="single-slide text-center">
                        <div className="container">
                          <div className="dsc">
                            <h1>
                              <span className="heading1"
                              data-aos="fade-up"
                              data-aos-offset="100"
                              data-aos-delay="5"
                              >
                                {heroContent?.mainHeading}
                              </span>
                            </h1>
                          </div>
                          <div className="button">
                            
                            {heroContent?.leftButtonLink ? (
                              <Link
                                to={heroContent?.leftButtonLink?.url}
                                className="white-btn"
                                target={heroContent?.leftButtonLink?.target}
                              >
                                {heroContent?.leftButtonLink?.title}
                              </Link>
                            ) : null}

                            {heroContent.rightButtonLink ? (
                              <Link
                                to={heroContent?.rightButtonLink?.url}
                                className="primary-btn"
                                target={heroContent?.rightButtonLink?.target}
                              >
                                {heroContent?.rightButtonLink?.title}
                              </Link>
                            ) : null}

                          </div>
                        </div>
                      </div>
                    </li>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HomepageHero
